
import {Component, Mixins, Vue} from "vue-property-decorator";
import FaizOranList from "@/components/lists/FaizOranList.vue";
import {FaizOranEntity} from "@/entity/FaizOranEntity";
import FormWrapper from "@/components/FormWrapper.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import HesapAlacakKalemiList from "@/components/lists/alacak-kalemi/HesapAlacakKalemiList.vue";
import HesapSekliPicker from "@/components/pickers/HesapSekliPicker.vue";
import {HesapSekli} from "@/enum/HesapSekli";
import FaizTuruPicker from "@/components/pickers/FaizTuruPicker.vue";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {FaizTuruPicker, HesapSekliPicker, FormWrapper, HesapAlacakKalemiList, FaizOranList}
})
export default class HesaplayiciView extends Mixins(ObjectInputMixin) {

  hesapSekli = HesapSekli;
  faizBitisTarihi: Date = new Date();
  alacakKalemleri: Array<AlacakKalemiEntity> = [];
  manuelFaiz: boolean = false;
  faizTuru: FaizTuruEntity | null = null;
  faizOranlari: Array<FaizOranEntity> = [];
  faizHesapSekli: HesapSekli = HesapSekli['365_gun'];
  faizlerItems: Array<any> = [];
  tutarVarMiError = false;
  oranVarMiError = false;

  faizlerHeaders = [
    {value: "baslangic_tarihi", text: "Başlangıç Tarihi"},
    {value: "bitis_tarihi", text: "Bitiş Tarihi"},
    {value: "uygulanan_oran", text: "Oran (%)"},
    {value: "tarih_farki", text: "Geçen Gün"},
    {value: "faiz_tutari", text: "Hesaplanan Faiz"},
  ];

  formClear() {
    this.faizlerItems = [];
  }

  calculate() {
    let data: any = {};
    data.hesap_sekli = this.faizHesapSekli;
    data.faiz_turu_id= this.faizTuru;
    data.tutarlar = this.alacakKalemleri;
    if (data.tutarlar) {
      this.tutarVarMiError = false;
    }
    StatusCodeHelper.dontShow422Alert = true;
    this.$http.post('/api/v1/hesaplayicilar/faiz', data)
        .then((response) => {
          this.faizlerItems = response.data
          StatusCodeHelper.dontShow422Alert = false;
        }).catch((error) => {
      if (error.response.status == 422) {
        if (error.response.data.errors?.tutarlar) {
          this.tutarVarMiError = true;
          this.input();
        }
      }
      StatusCodeHelper.dontShow422Alert = false;
    });
  }
}
