
import {Component, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class Oran extends SimpleInputMixin {
  @Prop({default: "Oran"}) label!: string;
  @Prop({default: false}) required!: any;
  @Prop() hideDetails!: boolean | string;
  @Prop() readonly !: boolean | string;
  @Prop() disabled!: boolean | string;

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isReadonly() {
    return this.readonly || this.readonly === "";
  }

  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  get rules() {
    let rules: Array<any> = [
      (value: number) => {
        return (value < 0) ? "Oran 0'dan küçük olamaz." : true
      }
    ];

    if(this.isRequired){
      rules.push(
          (value: number) => {
            return (value == 0|| value) ? true : "Lütfen oran giriniz.";
          }
      );
    }
    return rules;
  }

  input() {
    this.$emit("input", this.localValue);
  }

  get isRequired() {
    return this.required || this.required === "";
  }
}
