
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";

@Component({
  components: {
    DatabasePicker,
  },
  directives: {
    mask,
  },
})
export default class ParaBirimiPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() label!: string;
  @Prop({}) rules!: string;
  @Prop() readonly !: string | boolean;
  @Prop() hideDetails !: string | boolean;
  @Prop({default:true}) defaultFirst !: boolean;
  @Prop() clearable !: string | boolean;

  get isReadonly() {
    return this.readonly || this.readonly === "";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isClearable () {
    return this.clearable || this.clearable === "";
  }

  onParaBirimiChange(paraBirimi: ParaBirimiEntity) {
    this.$emit('para-birimi', paraBirimi);
    this.input()
  }
}
