
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import {AlacakBelgeTuruLabel} from "@/enum/AlacakBelgeTuru";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import Kur from "@/components/inputs/Kur.vue";

@Component({
  components: {Kur, ParaBirimiPicker, FormWrapper, Tutar, Dates},
})
export default class HesapAlacakKalemiForm extends Mixins(ObjectInputMixin) {
  @Prop() alacakBelgeTuruId!: number;
  paraBirimiTuru = ParaBirimi;
  paraBirimi !: ParaBirimiEntity;

  @Watch("tarih")
  onVadeTarihiChange() {
    this.localValue.faiz_baslangic_tarihi = this.tarih;
  }

  get tarih() {
    if (this.localValue.vade_tarihi) {
      return this.localValue.vade_tarihi;
    } else if (this.localValue.ibraz_tarihi) {
      return this.localValue.ibraz_tarihi;
    }
  }

  onParaBirimiIdChange(){
    this.localValue.para_birimi = this.paraBirimi
    this.input();
  }

  @Watch("aciklama")
  onAciklamaChange() {
    this.localValue.aciklama = this.aciklama;
  }

  get aciklama() {
    if (this.alacakBelgeTuruId) {
      return AlacakBelgeTuruLabel[this.alacakBelgeTuruId] + " Alacağı";
    }
  }
}
