export enum ParaBirimi
{
    TRY = 1,
    USD,
    EUR,
    AUD,
    BGN,
    CNY,
    DKK,
    GBP,
    IRR,
    CHF,
    SEK,
    JPY,
    CAD,
    QAR,
    KWD,
    NOK,
    PKR,
    RON,
    RUB,
    SAR
}

export enum ParaBirimiLabel
{
    "Türk Lirası"=1,
    "United States Dollar",
    "Euro",
    "Avustralya Doları",
    "Bulgar Levası",
    "Çin Yuanı",
    "Danimarka Kronu",
    "İngiliz Sterlini",
    "İran Riyali",
    "İsviçre Frangı",
    "İsveç Kronu",
    "Japon Yeni",
    "Kanada Doları ",
    "Katar Riyali",
    "Kuveyt Dinarı",
    "Norveç Kronu",
    "Pakistan Rupisi",
    "Rumen Leyi",
    "Rus Rublesi",
    "Suudi Arabistan Riyali"
}