
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";

@Component({
  components: {DatabasePicker},
})
export default class FaizTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() disabled!: boolean;
  @Prop({default: false}) isUpdate!: boolean;
  @Prop() action!: string;
  @Prop({default: "Faiz Türü"}) label!: string;
  @Prop() defaultId !: number;
  @Prop() rules !: string;

  get getDefaultId() {
    return this.defaultId;
  }

  filter(items: Array<FaizTuruEntity>) {
    return items.filter(item => !item.takip_id);
  }

  mounted() {
    this.input()
  }
}
