
import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {HesapSekli, HesapSekliAysiz, HesapSekliLabel} from "@/enum/HesapSekli";

@Component({
  components: {EnumPicker},
})
export default class HesapSekliPicker extends Mixins(SimpleInputMixin) {
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: false}) isMerkezBankasi!: boolean;
  @Prop({default: "Hesap Şekli"}) label!: string;
  @Prop() rules!: string;

  hesapSekliLabel = HesapSekliLabel;

  get turler() {
    if (this.isMerkezBankasi)
      return HesapSekliAysiz;
    else
      return HesapSekli
  }
}
