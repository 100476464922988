
import {Component, Mixins, Prop} from "vue-property-decorator";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import FaizOranForm from "@/components/forms/FaizOranForm.vue";
import {FaizOranEntity} from "@/entity/FaizOranEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakipEntity} from "@/entity/TakipEntity";

@Component({
  components: {
    DeleteDialog,
    FormDialog,
    FaizOranForm,
    ItemArrayManager,
  },
})
export default class FaizOranList extends Mixins(SimpleInputMixin) {
  @Prop({}) standart: any;
  @Prop() takipTarihi!: Date;
  headers = [
    {value: "tarih", text: "Başlangıç Tarihi"},
    {value: "oran", text: "Oran %", sortable: false},
  ];

  mounted() {
    if (!this.standart) {
      this.headers.push({
        value: "actions",
        text: "İşlemler",
        sortable: false,
      });
    }
  }

  oranGoster(aylikMi: boolean, aylikOran: number, yillikOran: number) {
    let aylik = aylikOran.toString().replace(".", ",");
    let yillik = yillikOran.toString().replace(".", ",");
    return aylikMi ? "Aylık " + aylik : "Yıllık " + yillik;
  }

  load() {
    this.$emit("load");
  }

  onAdd(item: FaizOranEntity) {
    this.localValue.push(item);
    this.input();
    this.$emit('value', this.localValue);
  }

  onUpdate(item: FaizOranEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
