export enum HesapSekli {
    "365_gun" = "365_gun",
    "360_yag" = "360_yag",
    "360_gun" = "360_gun",
    "aylik" = "aylik",
}

export enum HesapSekliAysiz {
    "365_gun" = "365_gun",
    "360_yag" = "360_yag",
    "360_gun" = "360_gun",
}

export enum HesapSekliLabel {
    "360_yag" = "360 Yıl-Ay-Gün",
    "360_gun" = "360 Gün",
    "365_gun" = "365 Gün",
    "aylik" = "30 Günlük Paket",
}

export enum HesapSekliAciklamaLabel {
    "360_gun" = "Hesabınız, faiz başlangıç tarihinden itibaren kaç gün geçtiyse takip öncesinde ve sonrasında bu esas alınarak yapılacaktır.",
    "365_gun" = "Hesabınız, faiz başlangıç tarihinden itibaren kaç gün geçtiyse takip öncesinde ve sonrasında bu esas alınarak yapılacaktır.",
    "360_yag" = "Hesabınız, faiz başlangıç tarihinden itibaren kaç gün geçtiyse takip öncesinde ve sonrasında bu esas alınarak yapılacaktır.",
    "aylik" = "Hesabınız, yeni aya girildiği tarihten itibaren takip öncesinde ve sonrasında bir aylık faiz tutarı eklenerek yapılacaktır. Seçiminizin hesap şekli ile ilgisi olmayıp, TT/ÖE’deki görünüm ile ilgilidir.",
}

export enum HesapSekliBelgeFormatiLabel {
    "360_yag" = "Yıllık",
    "360_gun" = "Yıllık",
    "365_gun" = "Yıllık",
    "aylik" = "Aylık",
}
