
import {Component, Mixins, Prop} from "vue-property-decorator";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {},
})
export default class Tutar extends Mixins(SimpleInputMixin) {
  @Prop() label!: string;
  @Prop() paraBirimi?: ParaBirimiEntity;
  @Prop() color!: string;
  @Prop({}) rules!: string;
  @Prop() disabled: any;
  @Prop({}) reverse!: any;
  @Prop({}) dense !: boolean;
  @Prop() hideDetails!: boolean | string;
  @Prop() appendIcon!: string;
  @Prop() loading!: boolean | string;

  localColor = this.color ? this.color : "";

  get isReverse() {
    return this.reverse || this.reverse === "";
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get isLoading() {
    return this.loading || this.loading === "";
  }

  mounted() {
    if (this.localValue)
      this.onValueChange();
  }

  input() {
    if (typeof this.localValue === "string")
      this.$emit("input", Number(this.localValue.replace(".", "").replace(",", ".")));
    return;
  }

  onValueChange() {
    this.localValue = this.value;
    if (typeof this.localValue === "number")
      this.localValue = this.localValue.toFixed(2).toString().replace(".", ",");
    else if (typeof this.localValue === 'string')
      this.localValue = this.localValue.replace(".", ",");
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 44 && (this.localValue.match(/,/g) || []).length == 1)
      evt.preventDefault();
    else if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode != 44)
      evt.preventDefault();
    else
      return true;
  }
}
