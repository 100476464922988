
import {Component, Mixins} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import HesapAlacakKalemiForm from "@/components/forms/alacak-kalemi/HesapAlacakKalemiForm.vue";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";

@Component({
  components: {
    HesapAlacakKalemiForm,
    DeleteDialog,
    FormDialog,
  },
})
export default class HesapAlacakKalemiList extends Mixins(SimpleInputMixin) {

  headers = [
    {value: "faiz_baslangic_tarihi", text: "Faiz Başlangıç Tarihi", class: 'my-header-style'},
    {value: "faiz_bitis_tarihi", text: "Faiz Bitiş Tarihi", class: 'my-header-style'},
    {value: "tutar", text: "Tutar", class: 'my-header-style'},
    {value: "para_birimi.kisa_isim", text: "Para Birimi", class: 'my-header-style'},
    {value: "actions", text: "İşlemler",align:'end', class: 'header-actions-style'},
  ]

  mounted() {
    if (!this.localValue) {
      this.localValue = [];
    }
  }

  onAdd(item: AlacakKalemiEntity) {
    this.localValue.push(item);
    this.input();
  }

  onUpdate(item: AlacakKalemiEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
    this.$emit('delete')
  }
}
