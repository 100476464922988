
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Oran from "@/components/inputs/Oran.vue";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {TakipEntity} from "@/entity/TakipEntity";

@Component({
  components: {Oran, Tutar, Dates, FormWrapper},
})
export default class FaizOranForm extends Mixins(ObjectInputMixin) {
@Prop({default:new Date().toISOString().slice(0,10)}) takipTarihi!: Date;

  aylikOran:boolean = false
  yillikOran:boolean = false


  faizRule(){
    if (this.localValue.tarih===null){
      return  "Lütfen bu alanı doldurun."
    }
    if (new Date(this.localValue.tarih).getTime() > new Date(this.takipTarihi).getTime()){
      return "Girilen Tarih, takip tarihinden ileri bir tarih olmamalıdır."
    }
  }

  onAylikOranChange() {
    this.localValue.yillik_oran = this.localValue.aylik_oran * 12;
    this.localValue.oran_aylik_mi=true;

    this.yillikOran = true;
    if (!this.localValue.aylik_oran){
      this.yillikOran = false;
    }
    this.input();
  }

  onYillikOranChange() {
    this.localValue.aylik_oran = this.localValue.yillik_oran / 12;
    this.localValue.oran_aylik_mi=false;

    this.aylikOran = true;
    if (!this.localValue.yillik_oran){
      this.aylikOran = false;
    }
    this.input();
  }
}
